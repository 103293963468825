<template>
  <el-dialog
    top="12vh"
    width="50vw"
    visible
    append-to-body
    :before-close="close"
    :show-close="false"
    custom-class="rounded"
  >
    <template #title>
      <div class="p-4 border-bottom">
        <div class="d-flex justify-content-between align-items-center">
          <span>
            <h2>{{ $t('payment.paymentMethodModal.title') }}</h2>
          </span>
          <span>
            <Button type="text" class="p-0 text-typography-primary action-btn" @click="close">
              <CloseIcon />
            </Button>
          </span>
        </div>
      </div>
    </template>
    <div v-loading="loading">
      <div class="mb-5">
        <el-radio-group v-model="paymentMethod" class="d-flex align-items-center" @change="resetInputs">
          <el-radio label="bankTransfer" class="d-flex column align-items-center">
            <p><BankTransferIcon /> {{ $t('payment.exports.paymentMethods.fullName.bankTransfer') }}</p>
          </el-radio>
          <el-radio label="cheque" class="d-flex column align-items-center">
            <p><ChequeIcon /> {{ $t('payment.exports.paymentMethods.fullName.cheque') }}</p>
          </el-radio>
          <el-radio label="creditCard" class="d-flex column align-items-center">
            <p><CreditCardIcon /> {{ $t('payment.exports.paymentMethods.fullName.creditCard') }}</p>
          </el-radio>
          <el-radio label="cash" class="d-flex column align-items-center">
            <p><CashIcon /> {{ $t('payment.exports.paymentMethods.fullName.cash') }}</p>
          </el-radio>
        </el-radio-group>
      </div>
      <div class="container g-0 mb7">
        <div v-if="paymentMethod === 'bankTransfer'" class="row">
          <div class="my-4">
            <small class="text-muted">
              {{ $t('payment.paymentMethodModal.labels.selectBankAccount') }}
            </small>
            <el-radio-group v-model="bankTransferType" class="d-flex flex-column">
              <el-radio
                label="supplier"
                class="my-4 mx-0 d-flex column align-items-center"
                :disabled="!supplierAccount"
              >
                <p>
                  {{
                    `${$t(
                      'payment.paymentMethodModal.labels.accountDefinedBySupplier'
                    )} ${accountDefinedBySupplierOption}`
                  }}
                </p>
              </el-radio>
              <el-radio
                label="directDebit"
                class="mb-4 mx-0 d-flex column align-items-center"
                :disabled="!supplierAccount"
              >
                <p>
                  {{
                    `${$t('payment.exports.paymentMethods.fullName.bankTransfer')} - ${$t(
                      'terms.supplierTerms.generalTerms.paymentMethodModal.directDebit'
                    )}`
                  }}
                </p>
              </el-radio>
              <el-radio label="other" class="mb-4 mx-0 d-flex column align-items-center">
                <p>{{ `${$t('commons.other')}...` }}</p>
              </el-radio>
            </el-radio-group>
          </div>

          <el-form ref="bankTransferForm" :model="bankTransferFormModel" class="mb-4" :show-message="false">
            <div class="row">
              <template v-if="bankTransferType === 'other'">
                <el-form-item prop="bankAccount.accountName" class="m-0 col-4">
                  <small class="text-muted">
                    {{ $t('supplierAccountDialog.tabs.paymentMethod.bankAccountName') }}
                  </small>
                  <el-input v-model="bankTransferFormModel.bankAccount.accountName" />
                </el-form-item>
                <el-form-item v-if="countryCode === 'IL'" prop="bankAccount.bankNumber" class="m-0 col-4">
                  <small class="text-muted">
                    {{ $t('supplierAccountDialog.tabs.paymentMethod.bank') }}
                  </small>
                  <el-select v-model="bankTransferFormModel.bankAccount.bankNumber" filterable class="w-100">
                    <el-option
                      v-for="(bankName, bankNumber) in supportedBanks"
                      :key="bankNumber"
                      :value="bankNumber"
                      :label="`${bankNumber} - ${bankName}`"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="countryCode === 'IL'" prop="bankAccount.branchNumber" class="m-0 col-2">
                  <small class="text-muted">
                    {{ $t('supplierAccountDialog.tabs.paymentMethod.bankBranchNumber') }}
                  </small>
                  <el-input v-model="bankTransferFormModel.bankAccount.branchNumber" type="number" />
                </el-form-item>
                <el-form-item v-if="countryCode === 'US'" prop="bankAccount.routingNumber" class="m-0 col-4">
                  <small class="text-muted">
                    {{ $t('supplierAccountDialog.tabs.paymentMethod.routingNumber') }}
                  </small>
                  <el-input v-model="bankTransferFormModel.bankAccount.routingNumber" type="number" />
                </el-form-item>
                <el-form-item
                  prop="bankAccount.accountNumber"
                  type="number"
                  :class="countryCode === 'IL' ? 'm-0 col-2' : 'm-0 col-4'"
                >
                  <small class="text-muted">
                    {{ $t('supplierAccountDialog.tabs.paymentMethod.bankAccountNumber') }}
                  </small>
                  <el-input v-model="bankTransferFormModel.bankAccount.accountNumber" type="number" />
                </el-form-item>
              </template>

              <el-form-item prop="reference" class="col-4 m-0">
                <small class="text-muted">
                  {{ $t('payment.paymentMethodModal.labels.reference') }}
                </small>
                <el-input v-model="bankTransferFormModel.reference" />
              </el-form-item>
            </div>
          </el-form>
        </div>

        <el-form
          v-else-if="paymentMethod === 'cheque'"
          ref="chequeForm"
          :model="chequeFormModel"
          class="mb-4 row"
          :show-message="false"
        >
          <el-form-item prop="dueDate" class="mb-0 col" style="margin-top: 3px">
            <div class="d-flex align-items-start flex-column">
              <small class="text-muted">
                {{ $t('payment.paymentMethodModal.labels.dueDate') }}
              </small>
              <el-date-picker
                v-model="chequeFormModel.dueDate"
                type="date"
                :format="$direction === 'ltr' ? 'MM.dd.yyyy' : 'dd.MM.yyyy'"
                :placeholder="`${$t('commons.select')}...`"
              />
            </div>
          </el-form-item>
          <el-form-item prop="chequeNumber" class="m-0 col">
            <small class="text-muted">
              {{ $t('payment.paymentMethodModal.labels.chequeNumber') }}
            </small>
            <el-input v-model="chequeFormModel.chequeNumber" type="number" />
          </el-form-item>
          <el-form-item prop="signedBy" class="m-0 col">
            <small class="text-muted">
              {{ $t('payment.paymentMethodModal.labels.signedBy') }}
            </small>
            <el-input v-model="chequeFormModel.signedBy" />
          </el-form-item>
        </el-form>

        <div v-else-if="paymentMethod === 'creditCard'" class="row">
          <div class="my-4">
            <small
              class="pb-2"
              :class="{ ['text-danger fw-bold']: !pickedCardId && creditCardError, ['text-muted']: pickedCardId }"
            >
              {{ $t('payment.paymentMethodModal.labels.selectCreditCard') }}
            </small>
            <el-radio-group v-model="pickedCardId" class="d-flex flex-column">
              <template v-if="creditCards.length">
                <el-radio
                  v-for="(creditCard, index) in creditCards"
                  :key="index"
                  :label="creditCard.id"
                  class="pt-2 mx-0 d-flex column align-items-center"
                >
                  <p>
                    <VisaIcon v-if="creditCard.type === 'visa'" />
                    <MastercardIcon v-if="creditCard.type === 'mastercard'" />
                    <AmericanExpressIcon v-if="creditCard.type === 'americanExpress'" />
                    <IsracardIcon v-if="creditCard.type === 'isracard'" />
                    <DinersIcon v-if="creditCard.type === 'diners'" />
                    {{
                      `${$t(`payment.paymentMethodModal.labels.${creditCard.type}`)} ${creditCard.number} - ${
                        creditCard.cardOwner
                      }`
                    }}
                  </p>
                </el-radio>
              </template>
              <el-radio v-else disabled class="pt-2 mx-0 d-flex column align-items-center">
                <p>
                  {{ $t('payment.paymentMethodModal.labels.creditCardsNotDefined') }}
                </p></el-radio
              >
              <el-radio label="other" class="pt-2 mx-0 d-flex column align-items-center">
                <p>{{ `${$t('commons.other')}...` }}</p>
              </el-radio>
            </el-radio-group>
          </div>
          <el-form
            v-if="pickedCardId"
            ref="creditCardForm"
            :model="creditCardFormModel"
            class="mb-4"
            :show-message="false"
          >
            <div v-if="pickedCardId === 'other'" class="row mb-2">
              <el-form-item prop="type" class="m-0 col-4">
                <small class="text-muted">
                  {{ $t('payment.paymentMethodModal.labels.cardType') }}
                </small>
                <el-select v-model="creditCardFormModel.type" filterable class="w-100">
                  <el-option
                    v-for="type in CREDIT_CARD_TYPE"
                    :key="type"
                    :value="type"
                    :label="`${$t(`payment.paymentMethodModal.labels.${type}`)} `"
                  >
                    <span :class="$direction === 'ltr' ? 'me-1' : 'ms-1'">
                      <VisaIcon v-if="type === 'visa'" />
                      <MastercardIcon v-if="type === 'mastercard'" />
                      <AmericanExpressIcon v-if="type === 'americanExpress'" />
                      <IsracardIcon v-if="type === 'isracard'" />
                      <DinersIcon v-if="type === 'diners'" />
                    </span>
                    <span>{{ $t(`payment.paymentMethodModal.labels.${type}`) }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="number" class="m-0 col-3" :rules="{ min: 4 }">
                <small class="text-muted">
                  {{ $t('payment.paymentMethodModal.labels.lastFourDigits') }}
                </small>
                <el-input :value="creditCardFormModel.number" type="number" @input="handleCreditCardNumber" />
              </el-form-item>
              <el-form-item prop="cardOwner" class="m-0 col-5">
                <small class="text-muted">
                  {{ $t('payment.paymentMethodModal.labels.cardOwner') }}
                </small>
                <el-input v-model="creditCardFormModel.cardOwner" />
              </el-form-item>
            </div>
            <el-checkbox v-model="creditCardFormModel.directDebit" class="m-0">
              <p>
                {{ `${$t('payment.paymentMethodModal.labels.directDebit')}` }}
              </p>
            </el-checkbox>
            <div class="row">
              <el-form-item prop="reference" class="m-0 col-4">
                <small class="text-muted">
                  {{ $t('payment.paymentMethodModal.labels.creditCompanyApprovalNumber') }}
                </small>
                <el-input v-model="creditCardFormModel.reference" />
              </el-form-item>
            </div>
          </el-form>
        </div>

        <p v-else-if="!paymentMethod" class="fw-bold">{{ $t('payment.paymentMethodModal.selectMethod') }}</p>
      </div>
    </div>
    <template #footer>
      <Button :disabled="loading" type="secondary" @click="close">
        {{ $t('commons.cancel') }}
      </Button>
      <Button :disabled="loading" @click="handleApplyButtonClick">
        {{ $t('commons.apply') }}
      </Button>
    </template>
  </el-dialog>
</template>

<script>
import { computed } from 'vue';
import { omit, pipe, reject, isNil, isEmpty, anyPass } from 'ramda';
import { DateTime } from 'luxon';

import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import {
  CreditCardIcon,
  ChequeIcon,
  BankTransferIcon,
  CashIcon,
  CloseIcon,
  VisaIcon,
  MastercardIcon,
  AmericanExpressIcon,
  IsracardIcon,
  DinersIcon,
} from '@/assets/icons';

import { useSupportedBanks } from '../../compositions';
import { CREDIT_CARD_TYPE } from '../../types';

export default {
  components: {
    Button,
    CreditCardIcon,
    ChequeIcon,
    BankTransferIcon,
    CashIcon,
    CloseIcon,
    VisaIcon,
    MastercardIcon,
    AmericanExpressIcon,
    IsracardIcon,
    DinersIcon,
  },
  props: {
    supplierAccount: { type: Object, default: () => null },
    creditCards: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup() {
    const { currentTenant } = useTenancy();
    const countryCode = computed(() => currentTenant.value?.countryCode);
    const { supportedBanks } = useSupportedBanks(countryCode);

    return { countryCode, supportedBanks };
  },
  data() {
    return {
      bankTransferType: '',
      paymentMethod: '',
      pickedCardId: '',
      creditCardError: false,
      bankTransferFormModel: {
        reference: '',
        bankAccount: {
          accountName: '',
          accountNumber: '',
          ...(this.countryCode === 'IL' && { branchNumber: '' }),
          ...(this.countryCode === 'IL' && { bankNumber: '' }),
          ...(this.countryCode === 'US' && { routingNumber: '' }),
        },
      },
      chequeFormModel: {
        chequeNumber: '',
        signedBy: '',
        dueDate: '',
      },
      creditCardFormModel: {
        type: '',
        number: '',
        cardOwner: '',
        reference: '',
        directDebit: false,
      },
      CREDIT_CARD_TYPE,
    };
  },
  computed: {
    accountDefinedBySupplierOption() {
      if (!this.supplierAccount) {
        return `(${this.$t('payment.paymentMethodModal.labels.notDefinedBySupplier')})`;
      }

      const accountDetails =
        this.countryCode === 'IL'
          ? `(${this.supportedBanks[this.supplierAccount.bankNumber]} (${this.supplierAccount.bankNumber}), ${this.$t(
              'payment.paymentMethodModal.labels.branch'
            )} ${this.supplierAccount.branchNumber}, ${this.$t('payment.paymentMethodModal.labels.account')} ${
              this.supplierAccount.accountNumber
            })`
          : `(${this.supplierAccount.routingNumber}, ${this.$t('payment.paymentMethodModal.labels.account')} ${
              this.supplierAccount.accountNumber
            })`;

      return accountDetails;
    },
  },
  methods: {
    resetInputs() {
      this.creditCardError = false;
      this.pickedCardId = null;
      this.resetModel(this.bankTransferFormModel);
      this.resetModel(this.chequeFormModel);
      this.resetModel(this.creditCardFormModel);
    },
    resetModel(model) {
      return (
        Object.keys(model).forEach(
          (k) =>
            (model[k] = typeof model[k] === 'object' && !(model[k] instanceof Date) ? this.resetModel(model[k]) : '')
        ),
        model
      );
    },
    handleCreditCardNumber(v) {
      if (v.match('^[0-9]{0,4}$')) this.creditCardFormModel.number = v;
    },
    async handleApplyButtonClick() {
      if (!this.paymentMethod) {
        this.$message.error(this.$t('payment.paymentMethodModal.validationErrorMessage'));
        return;
      }

      const paymentMethodData = {
        paymentMethod: this.paymentMethod,
      };

      switch (this.paymentMethod) {
        case 'cheque':
          {
            const valid = await this.$refs.chequeForm.validate().catch((err) => err);
            if (!valid) return;
            const { chequeNumber, signedBy, dueDate } = this.chequeFormModel;
            paymentMethodData.number = chequeNumber;
            paymentMethodData.signedBy = signedBy;
            paymentMethodData.dueDate = DateTime.fromJSDate(dueDate).toISODate();
          }
          break;
        case 'creditCard':
          {
            if (!this.$refs.creditCardForm) {
              this.creditCardError = true;
              return;
            }
            const valid = await this.$refs.creditCardForm.validate().catch((err) => err);
            if (!valid) return;
            const { type, number, cardOwner } =
              this.pickedCardId !== 'other'
                ? this.creditCards.find(({ id }) => id === this.pickedCardId)
                : this.creditCardFormModel;
            paymentMethodData.number = number;
            paymentMethodData.type = type;
            paymentMethodData.cardOwner = cardOwner;
            paymentMethodData.directDebit = this.creditCardFormModel.directDebit;
            paymentMethodData.reference = this.creditCardFormModel.reference;
          }
          break;
        case 'bankTransfer':
          switch (this.bankTransferType) {
            case 'other': {
              const valid = await this.$refs.bankTransferForm.validate().catch((err) => err);
              if (!valid) return;
              paymentMethodData.reference = this.bankTransferFormModel.reference;
              paymentMethodData.destinationBankAccount = this.bankTransferFormModel.bankAccount;
              break;
            }
            case 'supplier': {
              const valid = await this.$refs.bankTransferForm.validate().catch((err) => err);
              if (!valid) return;
              paymentMethodData.reference = this.bankTransferFormModel.reference;
              paymentMethodData.destinationBankAccount = pipe(
                omit(['__typename', 'id', 'businessId', 'filePath', 'filePathUrl', 'manualValidation']),
                reject(isNil)
              )(this.supplierAccount);
              break;
            }
            case 'directDebit':
              paymentMethodData.directDebit = true;
              break;

            default:
              break;
          }
          break;
      }

      this.$emit('paymentMethodUpdate', reject(anyPass([isNil, isEmpty]), paymentMethodData));
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  color: $typography-primary;
  padding: 1em;
}

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}

/* Chrome, Safari, Edge, Opera */
::v-deep input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
::v-deep input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

::v-deep .el-radio__input + .el-radio__label {
  color: $typography-primary;
}

::v-deep .el-date-editor {
  width: 100%;
}
</style>
