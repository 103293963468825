<template>
  <el-dialog top="12vh" visible append-to-body :before-close="close" :show-close="false" custom-class="rounded">
    <template #title>
      <div class="p-4 mb-2 border-bottom">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="mb-2">{{ payment.supplier.name }}</h2>
          <Button type="text" class="p-0 text-typography-primary actions-btn" @click="close">
            <CloseIcon />
          </Button>
        </div>
      </div>
    </template>
    <div v-loading="loading">
      <Table :columns="columns" :data="tableData" show-index border rounded hover class="mb-4" small>
        <template #cell-billingDate="{ rowData: { billingDate } }">
          <p>
            {{ formatDate(billingDate) }}
          </p>
        </template>
        <template
          #cell-billing="{
            rowData: {
              billing: { documentType, documentNumber, documentId },
            },
          }"
        >
          <Button
            type="link"
            class="fs-normal"
            @click.stop="handleOpenDocument({ documentId, documentIds: billingDocumentsIds })"
          >
            <p>{{ $t(`document.exports.schema.type.fullName.${documentType}`) }} {{ documentNumber }}</p>
          </Button>
        </template>
        <template #cell-status="{ rowData: { status } }">
          <Tag :type="status === 'paid' ? 'success' : 'neutral'">
            {{ translate(`table.${status}`) }}
          </Tag>
        </template>
      </Table>
      <DocumentModal
        v-if="displayedDocument.documentId"
        visible
        :document-id="displayedDocument.documentId"
        @close="displayedDocument = {}"
      >
        <template v-if="displayedDocument.documentIds && displayedDocument.documentIds.length" #header>
          <el-pagination
            layout="prev, pager, next"
            small
            background
            :page-size="1"
            :total="displayedDocument.documentIds.length"
            :current-page="displayedDocument.index + 1"
            :pager-count="5"
            @update:currentPage="handleDocumentModalPagination"
          />
        </template>
      </DocumentModal>
    </div>
    <template #footer>
      <div class="d-flex justify-content-end">
        <div>
          <p>{{ translate('total') }}</p>
          <h2 v-if="!loading">
            {{ formatMoney(amountLeftAfterUpdate) }}
          </h2>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref } from 'vue';
import { reject, isNil } from 'ramda';

import { CloseIcon } from '@/assets/icons';
import { Button, Table, Tag } from '@/modules/core';
import { DocumentModal } from '@/modules/documentModal';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { useUnpaidBillings, useBillings } from '../../compositions';
import { formatDate } from '../../tools/formatters';

const TABLE_HEADER = {
  BILLING_DATE: 'billingDate',
  BILLING: 'billing',
  TOTAL: 'totalBillingsAmount',
  LEFT_TO_PAY: 'leftToPay',
  STATUS: 'status',
};

export default {
  components: { DocumentModal, Button, Table, Tag, CloseIcon },
  props: {
    payment: { type: Object, required: true },
  },
  setup(props) {
    const { billings, loading: billingsLoading } = useBillings(computed(() => ({ ids: props.payment.billingIds })));

    const { unpaidBillings, loading: unpaidBillingsLoading } = useUnpaidBillings(
      computed(() => ({ businessId: props.payment.customer.id, supplierId: props.payment.supplier.id }))
    );

    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      formatDate,
      formatMoney,
      loading: computed(() => unpaidBillingsLoading.value || billingsLoading.value),
      billings,
      unpaidBillings,
      displayedDocument: ref({}),
    };
  },
  computed: {
    amountLeftAfterUpdate() {
      return this.payment.totalPayment.totalBilledAmount - this.payment.totalPayment.totalPaidAmount;
    },
    columns() {
      return [
        {
          header: this.translate('table.headers.billingDate'),
          key: TABLE_HEADER.BILLING_DATE,
        },
        {
          header: this.translate('table.headers.billings'),
          key: TABLE_HEADER.BILLING,
        },
        {
          header: this.translate('table.headers.totalBillingsAmount'),
          key: TABLE_HEADER.TOTAL,
        },
        {
          header: this.translate('table.headers.leftToPay'),
          key: TABLE_HEADER.LEFT_TO_PAY,
        },
        {
          header: this.translate('table.headers.status'),
          key: TABLE_HEADER.STATUS,
        },
      ];
    },
    tableData() {
      return this.billings
        .map(({ id, date, totalAmount, eventReferences: [{ document }] }) => {
          const unpaidBilling = this.unpaidBillings.find(({ billingId }) => billingId === id);

          return {
            [TABLE_HEADER.BILLING_DATE]: date,
            [TABLE_HEADER.BILLING]: {
              documentNumber: document?.documentNumber,
              documentId: document?.id,
              documentType: document?.type,
            },
            [TABLE_HEADER.TOTAL]: this.formatMoney(totalAmount / 100),
            [TABLE_HEADER.LEFT_TO_PAY]: unpaidBilling?.unpaidAmount
              ? this.formatMoney(unpaidBilling?.unpaidAmount)
              : '-',
            [TABLE_HEADER.STATUS]: unpaidBilling?.paidAmount ? 'partiallyPaid' : unpaidBilling ? 'notPaidYet' : 'paid',
          };
        })
        .sort((a, b) => new Date(a.billingDate) - new Date(b.billingDate));
    },
    billingDocumentsIds() {
      return reject(
        isNil,
        this.tableData.map(({ billing }) => billing.documentId)
      );
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    translate(key) {
      return this.$t(`payment.paymentInvoicesModal.${key}`);
    },
    handleOpenDocument({ documentId, documentIds }) {
      this.displayedDocument = {
        documentId,
        documentIds,
        index: documentIds?.indexOf(documentId),
      };
    },
    handleDocumentModalPagination(page) {
      if (page - 1 === this.displayedDocument.index) return;
      const nextIndex = page - 1;
      this.displayedDocument = {
        ...this.displayedDocument,
        documentId: this.displayedDocument.documentIds[nextIndex],
        index: nextIndex,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  overflow-y: hidden;
  padding: 1em;
}

.actions-btn {
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}
</style>
