import { onBeforeUnmount } from 'vue';

export default {
  props: {
    env: { type: String, default: 'sandbox' },
    selectAccount: Boolean,
    token: { type: String, required: true },
    product: { type: [String, Array], default: () => ['auth'] },
    receivedRedirectUri: { type: String, default: null },
    language: String,
    countryCodes: { type: Array, default: () => ['US'] },
    clientName: String,
    webhook: String,
    onLoad: Function,
    onSuccess: Function,
    onExit: Function,
    onEvent: Function,
  },
  setup(props) {
    const linkHandler = window.Plaid.create(props);
    linkHandler.open();

    onBeforeUnmount(() => {
      linkHandler.exit();
    });
  },
  render() {},
};
